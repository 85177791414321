.CalendarMonth {
  background-color: transparent;
}
.DateRangePickerInput {
  width: 318px;
  background-color: transparent;
}
.DateInput {
  background-color: transparent;
}
.DateRangePickerInput__withBorder {
  background-color: transparent;
  border: none;
}

.DateRangePickerInput_arrow {
  color: #13132b;
  width: 35px;
  & svg {
    fill: #13132b;
  }
}

.DateInput_input {
  background-color: transparent;
  background: #13132b;
  border-radius: 8px;
  border: 1px solid transparent;
  color: #fff;
}
.DateInput_input {
  background: rgba(255, 255, 255, 0.1);
  width: 155px;
  text-align: center;
}
.DateInput_input__focused {
  border: 1px solid #6f2acd;
}

.CalendarMonth_caption {
  color: #fff;
}
.DateRangePicker_picker,
.CalendarMonth,
.CalendarMonthGrid {
  background-color: #13132b;
}

.DayPicker_focusRegion,
.DayPicker_focusRegion_1 {
  background-color: #13132b;
  border: none;
}
.DateInput_fangStroke {
  fill: #13132b;
  stroke: #13132b;
}

.DayPicker,
.CalendarMonth_table,
.CalendarDay__blocked_out_of_range,
.CalendarDay__default,
.DayPickerNavigation_button__default {
  background-color: transparent;
  border: none;
  color: #fff;
  & svg {
    fill: #fff;
  }
}
.CalendarDay__selected_span {
  background-color: rgba(255, 255, 255, 0.05);
  border: none;
}

.CalendarDay__selected {
  background: #6f2acd;
  color: white;
  border: none;
  &:hover {
    border: none;
    border-radius: 8px;
  }
}
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  border: none;
  background-color: transparent;
}

.CalendarDay__selected:hover {
  background: #6f2acd;
  color: white;
}
.CalendarDay__default:hover {
  border: none;
  background-color: #6f2acd;
  color: white;
}

.CalendarDay__hovered_span {
  background: #13132b;
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  background: transparent;
  border: none;
  color: #cacccd;
}

.DayPickerNavigation_button {
  outline: none;
  outline-color: transparent;
  border: none !important;
}

.DayPickerNavigation_button:focus {
  background-color: transparent;
}
